export const baseUrl=  "https://dev-api-bbcantine.id2real.net";
//export const baseUrl=  "http://192.168.70.200:8091";


export const environment = {
  production: true,
  demo: 'saas', // other possible options are creative and modern
  GOOGLE_MAPS_API_KEY: 'AIzaSyDpgQMpcfx1QU-8SM-ljcgjG4xrYtIYby4',
  apiUrl: "https://dev-api-bbcantine.id2real.net",
  roleUrl:baseUrl+"/api/auth/role",
  groupeUrl:baseUrl+"/api/auth/group",
  userUrl:baseUrl+"/api/auth/user",
  platUrl:baseUrl+"/api/plat",
  menuUrl:baseUrl+"/api/menu",
  platMenuUrl:baseUrl+"/api/platMenu",
  commandeUrl:baseUrl+"/api/commande"
};
