import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../service/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        //const currentUser = this.authenticationService.currentUser();
        const currentToken = this.authenticationService.currentToken();
        //console.log(currentToken);
        
        if (currentToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentToken}`
                    //Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJZeUlPN2Zwam9QZmNqNlBWSDY3akVPOGV2d0VhOWVzaDBQWTdPXzVBYUo0In0.eyJleHAiOjE2NDQ0MjY4MDUsImlhdCI6MTY0NDQyNjUwNSwianRpIjoiMmI2NDY5YTQtNDgxZC00ZWI4LWE1YzQtZTYxYjY5MWU4MzE1IiwiaXNzIjoiaHR0cDovLzE5Mi4xNjguMTEuMTQ1OjgwODAvYXV0aC9yZWFsbXMvQkItUmVhbG0iLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiMTA1ZGQ2NjQtM2I5MC00ZWNhLWI5NDAtNjIwOWZjZDVhOGQyIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiQkItQ0FOVElORS1BUFAiLCJzZXNzaW9uX3N0YXRlIjoiMWM4NmEyZTEtZWI1NC00YzdlLThlZTAtYWVkNTBlMzg5Yjg5IiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwOi8vbG9jYWxob3N0OjQyMDAiXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbImRlZmF1bHQtcm9sZXMtYmItcmVhbG0iLCJvZmZsaW5lX2FjY2VzcyIsInVtYV9hdXRob3JpemF0aW9uIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJwcm9maWxlIGVtYWlsIiwic2lkIjoiMWM4NmEyZTEtZWI1NC00YzdlLThlZTAtYWVkNTBlMzg5Yjg5IiwiZW1haWxfdmVyaWZpZWQiOnRydWUsIm5hbWUiOiJhZG1pbiBhZG1pbiIsInByZWZlcnJlZF91c2VybmFtZSI6ImFkbWluIiwiZ2l2ZW5fbmFtZSI6ImFkbWluIiwiZmFtaWx5X25hbWUiOiJhZG1pbiIsImVtYWlsIjoiYWRtaW5AZ21haWwuY29tIn0.eqG_iawjXLdpGK7kbjpWDUcxox0c8HYuNaUfzIAO7ZzRpx8McYf7Wk7_oJVrUZHVuEYAJ03AvEvJ-s3mX3__aqgN9HiYPWexopF_c69S5JGkDk17jIPzkzS0_DeuxqLjamOMx_IvNCoOqCoFqNYekjFGcENpoG4IISJQufIieWsFA68Czcmx0kkYMd5pA8DMTIEdcdhE3JN8NrriO1TF84DlAQotre6r_RxpgQF42_mPqP3LcU7lqQ_K10ElZ6yQ3DwygpmzPlpdya0j5CdkgvlcOxsGVcKdMcgXHpaXuvxcUdA1MrNu2FJ0uFU7-x48YSkQBlitMbPkaxeDogJx1g`
                }
            });
        }

        return next.handle(request);
    }
}
