
export enum Habilitation{
    CREATE_CRENEAU = "CREATE_CRENEAU",
    UPDATE_CRENEAU = "UPDATE_CRENEAU", 
    DELETE_CRENEAU = "DELETE_CRENEAU", 
    LIST_CRENEAU = "LIST_CRENEAU",
    CREATE_CATEGORIE = "CREATE_CATEGORIE", 
    UPDATE_CATEGORIE = "UPDATE_CATEGORIE", 
    DELETE_CATEGORIE = "DELETE_CATEGORIE", 
    LIST_CATEGORIE = "LIST_CATEGORIE",
    CREATE_PLAT = "CREATE_PLAT", 
    UPDATE_PLAT = "UPDATE_PLAT", 
    DELETE_PLAT = "DELETE_PLAT", 
    LIST_PLAT = "LIST_PLAT",
    CREATE_AGENT = "CREATE_AGENT", 
    UPDATE_AGENT = "UPDATE_AGENT", 
    DELETE_AGENT = "DELETE_AGENT", 
    LIST_AGENT = "LIST_AGENT",
    CREATE_MENU = "CREATE_MENU", 
    UPDATE_MENU = "UPDATE_MENU", 
    DELETE_MENU = "DELETE_MENU", 
    LIST_MENU = "LIST_MENU",
    CREATE_MENU_SEMAINE = "CREATE_MENU_SEMAINE", 
    UPDATE_MENU_SEMAINE = "UPDATE_MENU_SEMAINE", 
    DELETE_MENU_SEMAINE = "DELETE_MENU_SEMAINE", 
    LIST_MENU_SEMAINE = "LIST_MENU_SEMAINE",
    CREATE_COMMANDE = "CREATE_COMMANDE", 
    UPDATE_COMMANDE = "UPDATE_COMMANDE", 
    DELETE_COMMANDE = "DELETE_COMMANDE", 
    LIST_COMMANDE = "LIST_COMMANDE",
    CREATE_USER = "CREATE_USER", 
    UPDATE_USER = "UPDATE_USER", 
    DELETE_USER = "DELETE_USER", 
    LIST_USER = "LIST_USER",
    CREATE_GROUPE = "CREATE_GROUPE", 
    UPDATE_GROUPE = "UPDATE_GROUPE", 
    DELETE_GROUPE = "DELETE_GROUPE", 
    LIST_GROUPE = "LIST_GROUPE",
    CREATE_ROLE = "CREATE_ROLE", 
    UPDATE_ROLE = "UPDATE_ROLE", 
    DELETE_ROLE = "DELETE_ROLE", 
    LIST_ROLE = "LIST_ROLE",
    SUPERADMIN = "SUPERADMIN",
    CREATE_TYPE_MENU = "CREATE_TYPE_MENU", 
    UPDATE_TYPE_MENU = "UPDATE_TYPE_MENU", 
    DELETE_TYPE_MENU = "DELETE_TYPE_MENU", 
    LIST_TYPE_MENU = "LIST_TYPE_MENU",
}